<template>
  <div>
    <div
      v-if="$can('read', 'user')"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="30"
            variant="light-secondary"
            class="badge-minimal"
          >
            <feather-icon
              icon="UserIcon"
              size="22"
            />
          </b-avatar>
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.first_name }} {{ userData.last_name }}
          </p>
        </template>
        <b-dropdown-item
          v-if="$can('edit', 'profile')"
          :to="{ name: 'Profile'}"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Mi cuenta</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </div>
    <div v-else>
      <b-nav-item
        @click="Open()"
      >
        <b-avatar
          size="30"
          variant="light-secondary"
          class="badge-minimal"
        >
          <feather-icon
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
        <span class="font-weight-bolder mb-0">
          Iniciar Sesión
        </span>
      </b-nav-item>
      <b-modal
        v-model="modalShow"
        cancel-variant="outline-secondary"
        ok-title="Login"
        header-bg-variant="primary"
        hide-backdrop
        size="sm"
        hide-footer
        centered
      >
        <template #modal-header>
          <div>
            <h5 class="text-white">
              {{ Title }}
            </h5>
            <p class="text-white">
              {{ SubTitle }}
            </p>
          </div>
        </template>
        <component
          :is="routerComponent"
          @resgisterCompleted="showComponent('vue-login')"
        >
          <template v-slot:register>
            <span
              class="text-primary"
              @click="showComponent('vue-register')"
            >
              Registrate
            </span>
          </template>
          <template v-slot:login>
            <span
              class="text-primary"
              @click="showComponent('vue-login')"
            >
              Iniciar sesión
            </span>
          </template>
          <template v-slot:forgot-password>
            <small
              class="text-primary"
              @click="showComponent('vue-forgot')"
            >
              ¿Olvidaste la contraseña?
            </small>
          </template>
        </component>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BNavItem,
  BModal, BButton, BContainer, BForm, BCol, BFormGroup, BFormInput, BFormCheckbox,
  BLink, BRow, BInputGroup, BInputGroupAppend, BCardText,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Login from '@/@core/layouts/components/app-navbar/components/Auth/Login.vue'
import Register from '@/@core/layouts/components/app-navbar/components/Auth/Register.vue'
import Forgot from '@/@core/layouts/components/app-navbar/components/Auth/Forgot.vue'
import ResetPassword from '@/@core/layouts/components/app-navbar/components/Auth/ResetPassword.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNavItem,
    BModal,
    BButton,
    BContainer,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BLink,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    'vue-login': Login,
    'vue-register': Register,
    'vue-forgot': Forgot,
    'vue-reset': ResetPassword,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      avatarText,
      modalShow: false,
      userEmail: null,
      password: null,
      Title: 'Iniciar Sesión',
      SubTitle: 'Iniciar sesión para agilizar tu compra.',
      routerComponent: 'vue-login',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      userData: 'userApp/getProfileData',
      tokenPassword: 'userApp/getToken',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    tokenPassword(value) {
      if (value.token != null) {
        if (this.userData.id == '') {
          this.setResetPassword()
        } else {
          localStorage.setItem('tabIndex', 2)
          this.$router.push({ name: 'Profile' })
        }
      } else {
        this.showComponent('vue-login')
      }
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$http.post('auth/logout')
      this.$ability.update(initialAbility)
      this.modalShow = false
      this.$router.push({ name: 'home' })
    },
    Open() {
      this.modalShow = !this.modalShow
      this.showComponent('vue-login')
    },
    setResetPassword() {
      this.modalShow = true
      this.showComponent('vue-reset')
    },
    showComponent(value) {
      switch (value) {
        case 'vue-login':
          this.Title = 'Iniciar Sesión'
          this.SubTitle = 'Iniciar sesión para agilizar tu compra.'
          break
        case 'vue-register':
          this.Title = 'Iniciar Sesión'
          this.SubTitle = 'Iniciar sesión para agilizar tu compra.'
          break
        case 'vue-reset':
          this.Title = 'Restablecer la contraseña🔒'
          this.SubTitle = 'Su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente'
          break
        case 'vue-forgot':
          this.Title = 'Recuperación de contraseña'
          this.SubTitle = 'Ingresa tu email para que podamos enviarte un link para restablecer la contraseña'
          break
        default:
          this.Title = 'Iniciar Sesión'
          this.SubTitle = 'Iniciar sesión para agilizar tu compra.'
      }
      this.routerComponent = value
    },
  },
}
</script>
